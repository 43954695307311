import React from "react"
import MainLayout from "../components/layout/mainLayout"
import { kudosData } from "../data/kudosData"
import Quote from "../components/quote"

const Kudos = props => {
  if (!kudosData) return null
  return (
    <>
      <MainLayout>
        <main className="Kudos">
          {kudosData.map(data => (
            <Quote
              author={data.author}
              author2={data.author2}
              authorLink={data.authorLink}
              quote={data.quote}
              link={data.link}
            />
          ))}
        </main>
      </MainLayout>
    </>
  )
}

export default Kudos
