export const kudosData = [
  {
    quote: "Review: ‘Divas’ is touching, funny, and fascinating.",
    author: "Zoë Madonna",
    author2: "The Boston Globe",
    authorLink: "",
    linkText: "",
    link:
      "http://www0.bostonglobe.com/arts/theater/dance/2018/06/24/divas-touching-funny-and-fascinating/xAEl8g4gJrZqBmz6tMv4qN/story.html",
  },
  {
    quote: "Preview: Slightly Bigger is Better When Two Small Troupes Pair Up.",
    author: "Terry Byrne",
    author2: "The Boston Globe",
    authorLink: "",
    linkText: "",
    link:
      "https://www.bostonglobe.com/arts/theater/dance/2017/09/06/slightly-bigger-better-when-two-small-troupes-pair/4FqUW3MktQFq1rHOmpntDO/story.html",
  },
  {
    quote:
      "A story of starving artists living and dying in penury and squalor runs the automatic risk of becoming flatly maudlin; thankfully, however, under the capable direction of the NEMPAC artists, the performance [La Bohème] was suffused with great warmth, humanity and vitality.",
    author: " Kate Stringer",
    author2: "Boston Musical Intelligencer",
    authorLink: "",
    linkText: "",
    link: "https://www.classical-scene.com/2015/06/29/boheme-nempac/",
  },
  {
    quote:
      "Kudos, as well, should be paid to director Adrienne Boris for pulling all of the various strings together without letting the various elements fly out of control … Boris’ clean and purposeful staging allows the actors to realize their full comic potential and the play to build on its gathering energy. It’s a production that as far as I can tell, doesn’t put a single foot wrong through its ninety minute run time.",
    author: "James Wilkinson",
    author2: "Boston Stage Notes",
    authorLink: "",
    linkText: "",
    link:
      "https://www.rabbitreviewsboston.com/home/2017/9/13/or-simple-machinemaiden-phoenix",
  },
  {
    quote:
      "Paired with an elegant but brief title, and the weight of a poetess icon, the show heaves with ambition before it’s even out of the gate. I did not leave disappointed. The show is magic, if magic is a thing that only exists as infinite possibility.",
    author: "Gillian Daniels",
    author2: "New England Theatre Geek",
    authorLink: "",
    linkText: "",
    link:
      "https://www.netheatregeek.com/2017/09/11/too-much-of-a-good-thing-is-just-wonderful-why-have-one-thing-or-the-other-when-you-can-have-it-all/",
  },
  {
    quote:
      "A fine musician herself, Adrienne Boris’s imaginative and innovative stagings are catalyzed by a deep knowledge of—and a great respect for—the musical score. An inquisitive director, her stagings are well-researched, conceived, and prepared: she has a strong vision for her work, but is always open to new ideas as the rehearsal process unfolds. Singers love working with her because her own vocal training ensures that she is at once respectful of their needs and able to explain things in both a physical and musical way. It is always a pleasure to work with her.",
    author: " Edward Elwyn Jones",
    author2:
      "University Organist and Choirmaster at Harvard University and Music Director, Lowell House Opera",
    authorLink: "http://www.edwardelwynjones.com",
    linkText: "",
    link: "",
  },
  {
    quote:
      "Adrienne has directed several projects for The Nora for our That's What She Said series and I am continually impressed with her wonderful combination of head and heart. Her intelligence is matched by her craft and a dynamic use of space and she has a real-world understanding of producing challenges and finds a way to fulfill her vision at any level. I look forward to strengthening our connection on future projects.",
    author: "Lee Mikeska Gardner",
    author2: "Artistic Director, The Nora Theatre Company",
    authorLink: "https://www.centralsquaretheater.org/people/lee-mikeska-gardner",
    linkText: "",
    link: "",
  },
  {
    quote:
      "An accomplished artist, Adrienne's direction is graceful, elegant and intelligent  She  is equally at home with dramatic and comedic material, always with a keen eye focused on both our human frailty and our capacity for joy, generosity and humor. Adrienne is a seasoned professional but one who is always bringing a fresh and open approach to each new project. Adrienne has been part of our annual festival of short operas for a number of years and we're always confident that her work will be of the highest quality!",
    author: "Patricia-Maria Weinmann",
    author2:
      "Co-Artistic Director, Boston Opera Collaborative and former stage directing faculty at New England Conservatory of Music",
    authorLink: "http://patriciaweinmann.com/",
    linkText: "",
    link: "",
  },
  {
    quote:
      "Adrienne creates a safe and comfortable rehearsal environment to allow performers to explore deeper artistry. She encourages performers to make their own choices and find their own truths under her bold direction. Adrienne is a sincere and genuine leader. She helps her cast to be vulnerable and present in the moment.",
    author: "Taka Komagata",
    author2: "Tenor",
    authorLink: "http://www.takakomagata.com/",
    linkText: "",
    link: "",
  },
]
